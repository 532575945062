import React, { Component } from "react";

import "./Nav.css";
import logo from "./assets/logo.svg";
import Modal from "./Modal";

class Nav extends Component {
  state = { showAbout: false };

  render() {
    return (
      <nav className="Nav">
        <a
          className="Nav-logo"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.lemediatv.fr"
        >
          <img src={logo} alt="Le Média" />
        </a>

        <div>
          <a href="mailto:contact@amnistiegj.fr">
            <i className="fa-fw fas fa-envelope" />
          </a>
          <button onClick={() => this.setState({ showAbout: true })}>
            <i className="fa-fw fas fa-question-circle" />
          </button>
        </div>
        <Modal
          onClose={() => this.setState({ showAbout: false })}
          isOpen={this.state.showAbout}
          title="À propos"
        >
          <div className="Nav-about">
            <h2>Mentions légales</h2>
            <p>
              Le présent site est géré par l'association Le Média, 242 boulevard
              Voltaire, 75011 Paris. Pour toute suggestion, information,
              réaction concernant exclusivement cette pétition vous pouvez nous
              contacter <a href="mailto:contact@amnistiegj.fr">par email</a>.
            </p>
            <h2>Données personnelles</h2>
            <p>
              Nous ne recueillons sur ce site web aucune information permettant
              l'identification des personnes, hormis celles qui nous sont
              volontairement transmises via le formulaire de la pétition.
            </p>
            <p>
              Ni votre nom ni vos coordonnées ne seront rendus publics sur ce
              site. Seul Le Média et le destinataire de cette pétition pourront
              avoir accès à ces données.
            </p>
            <p>
              Le Média protège vos informations personnelles. Elles ne seront
              jamais vendues, échangées ou louées à d'autres organisations. Vous
              bénéficiez d'un droit d'accès, de rectification et de suppression
              de vos informations personnelles, que vous pouvez exercer{" "}
              <a href="mailto:contact@amnistiegj.fr">en nous écrivant</a>.
            </p>
          </div>
        </Modal>
      </nav>
    );
  }
}

export default Nav;
