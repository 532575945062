import React from "react";
import ReactModal from "react-modal";

import "./Modal.css";

ReactModal.setAppElement("#root");

const Modal = ({ title, children, onClose, isOpen }) => {
  return (
    <ReactModal
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { backgroundColor: "rgba(254, 237, 0, 0.75)" },
        content: {
          background: "transparent",
          border: 0,
          borderRadius: 0,
          bottom: 0,
          left: 0,
          padding: 0,
          pointerEvents: "none",
          right: 0,
          top: 0
        }
      }}
    >
      <div className="Modal">
        <h1>
          {title}
          <button onClick={onClose}>
            <i className="far fa-times-circle" />
          </button>
        </h1>
        <div>{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
