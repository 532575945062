import React, { Component, Fragment } from "react";
import qs from "qs";

import Validation from "./Validation";
import Content from "./Content";
import Aside from "./Aside";

class Home extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      location: { search }
    } = props;
    const params = qs.parse(search, { ignoreQueryPrefix: true });

    this.state = { token: params.token };
    history.replace("/");
  }

  render() {
    return (
      <Fragment>
        {this.state.token && <Validation token={this.state.token} />}
        <Content />
        <Aside />
      </Fragment>
    );
  }
}

export default Home;
