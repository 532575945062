import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import validator from "validator";

import "./SignIt.css";
import Modal from "./Modal";
import Share from "./Share";

class SignIt extends Component {
  state = {
    showModal: false,
    error: null
  };

  render() {
    return (
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          zipcode: "",
          country: "France",
          newsletter: "enabled"
        }}
        validate={values => {
          let errors = {};

          if (!values.firstname) {
            errors.firstname = "Un prénom est requis.";
          }

          if (!values.lastname) {
            errors.lastname = "Un nom de famille est requis.";
          }

          if (!values.email) {
            errors.email = "Une adresse email est requise.";
          } else if (!validator.isEmail(values.email)) {
            errors.email = "L'adresse email est invalide";
          }

          if (!values.zipcode) {
            errors.zipcode = "Un code postal est requis.";
          }

          if (!values.country) {
            errors.country = "Un pays est requis.";
          }

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          this.setState({ error: null });
          axios
            .post(
              "https://us-central1-le-media.cloudfunctions.net/amnestySign",
              values
            )
            .then(() => {
              this.setState({ showModal: true });
            })
            .catch(e => this.setState({ error: e.response.data }))
            .then(() => resetForm());
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="SignIt">
            <Modal
              onClose={() => this.setState({ showModal: false })}
              isOpen={this.state.showModal}
              title="Confirmez votre signature et partagez"
            >
              <p>
                Merci, vous allez très rapidement recevoir un email qui vous
                indiquera comment <strong>confirmer</strong> votre signature.
              </p>
              <p>
                En attendant, pour que cette pétition se diffuse et que tout le
                monde soit au courant n'hésitez pas à la{" "}
                <strong>partager</strong> le plus largement possible !
              </p>
              <div className="Modal-share">
                <Share />
              </div>
            </Modal>
            <fieldset>
              <label htmlFor="firstname">
                Prénom <span>*</span>
              </label>
              <Field name="firstname" id="firstname" />
              <ErrorMessage name="firstname" component="div" />
            </fieldset>
            <fieldset>
              <label htmlFor="lastname">
                Nom de famille <span>*</span>
              </label>
              <Field name="lastname" id="lastname" />
              <ErrorMessage name="lastname" component="div" />
            </fieldset>
            <fieldset>
              <label htmlFor="email">
                Email <span>*</span>
              </label>
              <Field type="email" name="email" id="email" />
              <ErrorMessage name="email" component="div" />
            </fieldset>
            <fieldset>
              <label htmlFor="zipcode">
                Code postal <span>*</span>
              </label>
              <Field name="zipcode" id="zipcode" />
              <ErrorMessage name="zipcode" component="div" />
            </fieldset>
            <fieldset>
              <label htmlFor="country">
                Pays <span>*</span>
              </label>
              <Field name="country" id="country" />
              <ErrorMessage name="country" component="div" />
            </fieldset>
            <fieldset className="SignIt-newsletter">
              <div>
                <Field
                  type="radio"
                  name="newsletter"
                  id="newsletter-ok"
                  value="enabled"
                  checked={values.newsletter === "enabled"}
                />
                <label htmlFor="newsletter-ok">
                  Je souhaite être informé.e des suites de cette pétition et des
                  autres initiatives du Média par email.
                </label>
              </div>
              <div>
                <Field
                  type="radio"
                  name="newsletter"
                  id="newsletter-no"
                  value="disabled"
                  checked={values.newsletter === "disabled"}
                />
                <label htmlFor="newsletter-no">
                  Je ne veux pas suivre les progrès de cette pétition.
                </label>
              </div>
            </fieldset>
            {this.state.error && (
              <div className="SignIt-error">{this.state.error}</div>
            )}
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Fragment>
                  <i className="fa-spin fas fa-spinner" /> En cours ...
                </Fragment>
              ) : (
                <Fragment>
                  <i className="fas fa-pen" /> Je signe la pétition
                </Fragment>
              )}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SignIt;
