import React, { Component, Fragment } from "react";
import axios from "axios";

import "./Validation.css";
import Modal from "./Modal";
import Share from "./Share";

class Validation extends Component {
  state = {
    showModal: true,
    isLoading: false
  };

  componentDidMount() {
    axios
      .post(
        "https://us-central1-le-media.cloudfunctions.net/amnestyValidation",
        {
          token: this.props.token
        }
      )
      .then(() => this.setState({ isLoading: false }))
      .catch(err => console.log(err));
  }

  render() {
    return (
      <Modal
        onClose={() => this.setState({ showModal: false })}
        isOpen={this.state.showModal}
        title="Merci pour votre engagement !"
      >
        <div className="Validation">
          {this.state.isLoading ? (
            <div className="Validation-loading">
              <i className="fa-spin fas fa-spinner" />
            </div>
          ) : (
            <Fragment>
              <p>
                Votre signature est <strong>confirmée</strong> !
              </p>
              <p>
                Pour que cette pétition se diffuse et que tout le monde soit au
                courant n'hésitez pas à la <strong>partager</strong> le plus
                largement possible.
              </p>
              <div className="Modal-share">
                <Share />
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    );
  }
}

export default Validation;
