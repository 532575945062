import React from "react";

import "./Aside.css";
import SignIt from "./SignIt";
import Share from "./Share";
import Counter from "./Counter";

const Aside = () => {
  return (
    <aside className="Aside">
      <Counter />
      <SignIt />
      <Share />
    </aside>
  );
};

export default Aside;
