import React from "react";

import "./Header.css";

const Header = () => {
  return (
    <div className="Header">
      <div className="wrapper">
        <header>
          <h1>
            Exigeons l'amnistie
            <br />
            des Gilets Jaunes&nbsp;:
            <br />
            <strong>Signez la pétition</strong>
          </h1>
          <h2>#AmnistiePourLesGJ</h2>
        </header>
      </div>
    </div>
  );
};

export default Header;
