import React from "react";

import "./Content.css";

const Content = () => {
  return (
    <section className="Content">
      <p>
        <strong>
          Ils n’étaient pas venus à Paris, Bordeaux, Toulouse, et tant d’autres
          villes, pour en découdre avec les forces de l’ordre.
        </strong>{" "}
        Ils étaient venus pour défendre leurs vies menacées, dégradées par la
        précarité, mises en joue par un pouvoir prêt à faire les poches de leurs
        parents retraités comme de leurs enfants étudiants, afin de valoriser le
        patrimoine financier des plus riches. Ils étaient venus pour protéger
        leurs droits fondamentaux, et aussi une certaine conception de la
        justice sociale, qui a longtemps fait l’honneur de la France.
      </p>
      <p>
        La stratégie de la tension, délibérément choisie par le pouvoir, ne leur
        a pas laissé la chance d’une protestation pacifique. Gazage, nassage et
        brimades en tout genre, puis au fil des semaines, matraquages, tirs de
        flashballs et grenades mutilantes, pour la première fois de leur
        existence, beaucoup de « Gilets jaunes » ont été confrontés à des
        brutalités policières indignes d'une démocratie, que des organisations
        comme Amnesty international ont elles-mêmes dénoncées.
      </p>
      <p>
        <strong>
          On ne compte désormais plus le nombre de manifestants, au casier
          vierge, mis en garde à vue, condamnés en comparution immédiate, ou
          aujourd’hui en instance de jugement.
        </strong>{" "}
        Certains connaîtront la prison, qui détruit, qui humilie. D’autres ne
        retrouveront jamais d’emploi. Un grand nombre d’entre eux verront leurs
        vies brisées à jamais. Nous ne pouvons accepter qu’une protestation
        contre une politique massivement rejetée par la population détruise
        ainsi des êtres pris pour boucs émissaires. Nous ne pouvons accepter
        qu’un pouvoir, qui a sciemment fait le choix de la confrontation, se
        serve d’eux pour intimider tout un peuple. Nous ne pouvons accepter
        l’idée d’une vengeance d’Etat.
      </p>
      <p>
        <strong>
          C’est pourquoi nous appelons le Président de la République, Emmanuel
          Macron, et son Premier Ministre, Edouard Philippe, à mettre à l’ordre
          du jour une loi d’amnistie en faveur des hommes et des femmes
          aujourd’hui persécutés, mis en examen, ou détenus pour avoir participé
          à ce mouvement social d’ampleur historique.
        </strong>{" "}
        C’est pourquoi nous appelons dès aujourd’hui à l’arrêt des procédures en
        cours, à l’effacement des peines prononcées, et à ce qu’une réponse
        enfin politique, et pas seulement sécuritaire et judiciaire, soit donnée
        aux événements hors du commun que la France est en train de vivre.
      </p>
      <p>
        Une telle amnistie est prévue par l’article 133-9 du Code pénal et
        légitimée par la tradition française, de nombreuses lois d’amnistie
        ayant ainsi été votées depuis le début de la 5ème République, notamment
        dans le cadre d’activités protestataires.
      </p>
      <p>
        Un tel geste serait seul en mesure de commencer à rendre au pays
        l’apaisement qu’il ne saurait retrouver sur fond de persécutions pour
        l’exemple. « Nous ne reprendrons pas le cours de nos vies », disiez-vous
        Monsieur le Président, le 10 décembre 2018. Nous ne reprendrons pas le
        cours de nos vies tant que nos concitoyens n’auront pas pleinement
        retrouvé la leur.
      </p>
      <h2>Les premiers signataires</h2>
      <ul>
        <li>
          <strong>Isabelle Alonso</strong> (écrivaine)
        </li>
        <li>
          <strong>Alain Badiou</strong> (philosophe)
        </li>
        <li>
          <strong>François Bégaudeau</strong> (écrivain)
        </li>
        <li>
          <strong>Juan Branco</strong> (avocat)
        </li>
        <li>
          <strong>Stéphane Brizé</strong> (réalisateur)
        </li>
        <li>
          <strong>Mathieu Burnel</strong> (ex-inculpé dans l’affaire Tarnac)
        </li>
        <li>
          <strong>Annick Coupé</strong> (secrétaire générale d’Attac)
        </li>
        <li>
          <strong>Alain Damasio</strong> (écrivain)
        </li>
        <li>
          <strong>Eric Drouet</strong> (gilet jaune)
        </li>
        <li>
          <strong>Annie Ernaux</strong> (écrivain)
        </li>
        <li>
          <strong>Gérard Filoche</strong> (membre de la CGT, Attac, fondation
          Copernic)
        </li>
        <li>
          <strong>Bernard Friot</strong> (sociologue et économiste)
        </li>
        <li>
          <strong>Thomas Guénolé</strong> (politologue)
        </li>
        <li>
          <strong>Eric Hazan</strong> (éditeur){" "}
        </li>
        <li>
          <strong>Anasse Kazib</strong> (délégué Sud Rail)
        </li>
        <li>
          <strong>Zoé Konstantopoulou</strong> (avocate, ex-présidente du
          parlement grec)
        </li>
        <li>
          <strong>David Koubbi</strong> (avocat)
        </li>
        <li>
          <strong>Mourad Laffitte</strong> (réalisateur)
        </li>
        <li>
          <strong>Aude Lancelin</strong> (directrice de publication Le Média)
        </li>
        <li>
          <strong>Franck Lepage</strong> (éducateur populaire)
        </li>
        <li>
          <strong>David Libeskind</strong> (avocat, collectif « robe noire et
          gilet jaune »)
        </li>
        <li>
          <strong>Frédéric Lordon</strong> (économiste et philosophe)
        </li>
        <li>
          <strong>Henri Maler</strong> (fondateur d’Acrimed)
        </li>
        <li>
          <strong>Jean-Claude Michéa</strong> (philosophe)
        </li>
        <li>
          <strong>Gérard Mordillat</strong> (réalisateur)
        </li>
        <li>
          <strong>Maxime Nicolle</strong> (gilet jaune)
        </li>
        <li>
          <strong>Gérard Noiriel</strong> (historien)
        </li>
        <li>
          <strong>Osons causer</strong> (YouTuber)
        </li>
        <li>
          <strong>Gilles Perret</strong> (réalisateur)
        </li>
        <li>
          <strong>Pierre Perret</strong> (auteur-compositeur-interprète)
        </li>
        <li>
          <strong>Thomas Portes</strong> (PCF, syndicaliste CGT-cheminots)
        </li>
        <li>
          <strong>Bernard Stiegler</strong> (philosophe)
        </li>
        <li>
          <strong>Assa Traoré</strong> (comité Adama)
        </li>
        <li>
          <strong>Eric Vuillard</strong> (écrivain)
        </li>
      </ul>

      <ul>
        <li>
          <strong>Joseph Andras</strong> (écrivain)
        </li>
        <li>
          <strong>Fabien Archambault</strong> (historien)
        </li>
        <li>
          <strong>Dorian Astor</strong> (philosophe)
        </li>
        <li>
          <strong>Marc Belissa</strong> (historien)
        </li>
        <li>
          <strong>Yves Bernanos</strong> (réalisateur)
        </li>
        <li>
          <strong>Olivier Berruyer</strong> (fondateur du blog « Les Crises »)
        </li>
        <li>
          <strong>Olivier Besancenot</strong> (porte-parole du NPA, facteur)
        </li>
        <li>
          <strong>Alain Bihr</strong> (professeur émérite de sociologie)
        </li>
        <li>
          <strong>Laurent Binet</strong> (écrivain){" "}
        </li>
        <li>
          <strong>Irène Bonnaud</strong> (metteuse en scène, traductrice)
        </li>
        <li>
          <strong>Véronique Bontemps</strong> (anthropologue, CNRS)
        </li>
        <li>
          <strong>Yannick Bosc</strong> (historien)
        </li>
        <li>
          <strong>Alain Boscus</strong> (historien)
        </li>
        <li>
          <strong>Youcef Brakni</strong> (comité Adama)
        </li>
        <li>
          <strong>Gérard Bras</strong> (philosophe)
        </li>
        <li>
          <strong>Mathieu Brunet</strong> (enseignant chercheur)
        </li>
        <li>
          <strong>Gael Brustier</strong> (écrivain)
        </li>
        <li>
          <strong>Emmanuel Burdeau</strong> (critique de cinéma)
        </li>
        <li>
          <strong>Pascal Buresi</strong> (historien, CNRS)
        </li>
        <li>
          <strong>Vanessa Caru</strong> (historienne, CNRS)
        </li>
        <li>
          <strong>Martial Cavatz</strong> (enseignant)
        </li>
        <li>
          <strong>Vincent Cespedes</strong> (philosophe)
        </li>
        <li>
          <strong>Alexis Charansonnet</strong> (historien)
        </li>
        <li>
          <strong>Yves Cohen</strong> (historien)
        </li>
        <li>
          <strong>Francis Combes</strong> (poète, éditeur)
        </li>
        <li>
          <strong>Sergio Coronado</strong> (militant écologiste)
        </li>
        <li>
          <strong>Mirabelle Cruells Thouvenot</strong> (attachée territoriale)
        </li>
        <li>
          <strong>François Cusset</strong> (écrivain)
        </li>
        <li>
          <strong>Sonia Dayan-Herzbrun</strong> (sociologue)
        </li>
        <li>
          <strong>Laurence De Cock</strong> (historienne, fondation Copernic)
        </li>
        <li>
          <strong>Christine Delphy</strong> (sociologue, CNRS)
        </li>
        <li>
          <strong>Martine Derrier</strong> (spectacle vivant)
        </li>
        <li>
          <strong>Dany-Robert Dufour</strong> (philosophe)
        </li>
        <li>
          <strong>François Dumasy</strong> (historien)
        </li>
        <li>
          <strong>Bertrand Dumenieu</strong> (ingénieur)
        </li>
        <li>
          <strong>Mireille Fanon-Mendès-France</strong> (ancienne experte de
          l'ONU)
        </li>
        <li>
          <strong>Pascale Fautrier</strong> (écrivaine)
        </li>
        <li>
          <strong>Christian Ferrié</strong> (enseignant)
        </li>
        <li>
          <strong>Jean-Louis Fournel</strong> (italianiste)
        </li>
        <li>
          <strong>Geneviève Fraisse</strong> (CNRS)
        </li>
        <li>
          <strong>Bruno Gaccio</strong> (scénariste et producteur)
        </li>
        <li>
          <strong>Fanny Gallot</strong> (historienne)
        </li>
        <li>
          <strong>Isabelle Garo</strong> (philosophe)
        </li>
        <li>
          <strong>Susan George</strong> (présidente d’ATTAC)
        </li>
        <li>
          <strong>Laurence Giavarini</strong> (universitaire)
        </li>
        <li>
          <strong>Boris Gobille</strong> (sciences politiques)
        </li>
        <li>
          <strong>François Godicheau</strong> (historien)
        </li>
        <li>
          <strong>Maurizio Gribaudi</strong> (historien)
        </li>
        <li>
          <strong>Nacira Guénif</strong> (sociologue)
        </li>
        <li>
          <strong>Pierre Guéry</strong> (auteur)
        </li>
        <li>
          <strong>André Gunthert</strong> (historien)
        </li>
        <li>
          <strong>Elie Haddad</strong> (historien, CNRS)
        </li>
        <li>
          <strong>Florence Johsua</strong> (politiste)
        </li>
        <li>
          <strong>Leslie Kaplan</strong> (écrivaine)
        </li>
        <li>
          <strong>Sam Karmann</strong> (acteur)
        </li>
        <li>
          <strong>Pierre Khalfa</strong> (syndicaliste)
        </li>
        <li>
          <strong>Stéphane Kipfer</strong> (politiste, géographe, Toronto)
        </li>
        <li>
          <strong>Isabelle Krzywkowski</strong> (universitaire, SNESUP-FSU)
        </li>
        <li>
          <strong>Jérôme Lamy</strong> (historien)
        </li>
        <li>
          <strong>Dany Lang</strong> (économiste)
        </li>
        <li>
          <strong>Mathieu Larnaudie</strong> (écrivain)
        </li>
        <li>
          <strong>Olivier Le Cour Grandmaison</strong> (universitaire)
        </li>
        <li>
          <strong>Gildas Le Dem</strong> (journaliste)
        </li>
        <li>
          <strong>Frédéric Lebaron</strong> (sociologue)
        </li>
        <li>
          <strong>Marie-Thérèse Lenoir</strong> (MRAP migrations)
        </li>
        <li>
          <strong>François Lescure</strong> (mathématicien)
        </li>
        <li>
          <strong>Michel Letté</strong> (historien)
        </li>
        <li>
          <strong>Ivan Marin</strong> (mathématicien)
        </li>
        <li>
          <strong>Céline Martin</strong> (historienne)
        </li>
        <li>
          <strong>Marion Messina</strong> (écrivain)
        </li>
        <li>
          <strong>Régis Meyran</strong> (chercheur en sciences sociales,
          journaliste)
        </li>
        <li>
          <strong>René Monzat</strong> (auteur, réalisateur)
        </li>
        <li>
          <strong>Dominique Natanson</strong> (union française des juifs pour la
          paix)
        </li>
        <li>
          <strong>Heitor O'Dwyer de Macedo</strong> (psychanalyste)
        </li>
        <li>
          <strong>Ugo Palheta</strong> (sociologue)
        </li>
        <li>
          <strong>Willy Pelletier</strong> (sociologue, fondation Copernic)
        </li>
        <li>
          <strong>Roland Pfefferkorn</strong> (sociologue)
        </li>
        <li>
          <strong>Emmanuelle Posse</strong> (professeur de philosophie, mère
          d'un gilet jaune condamné)
        </li>
        <li>
          <strong>Raphaël Pradeau</strong> (porte-parole d'ATTAC)
        </li>
        <li>
          <strong>Olivier Rabourdin</strong> (acteur)
        </li>
        <li>
          <strong>Jacques Rancière</strong> (philosophe)
        </li>
        <li>
          <strong>Eugenio Renzi</strong> (critique de cinéma, professeur de
          philosophie)
        </li>
        <li>
          <strong>Fabrice Riceputi</strong> (historien)
        </li>
        <li>
          <strong>Michèle Riot-Sarcey</strong> (historienne)
        </li>
        <li>
          <strong>Laurent Ripart</strong> (historien)
        </li>
        <li>
          <strong>Juliette Rousseau</strong> (militante, autrice)
        </li>
        <li>
          <strong>Arnaud Saint-Martin</strong> (citoyen)
        </li>
        <li>
          <strong>Catherine Samary</strong> (économiste)
        </li>
        <li>
          <strong>Benoît Schneckenburger</strong> (agrégé de philosophie)
        </li>
        <li>
          <strong>Todd Shepard</strong> (historien)
        </li>
        <li>
          <strong>Patrick Simon</strong> (revue Mouvements)
        </li>
        <li>
          <strong>Danielle Simonnet</strong> (conseillère de Paris)
        </li>
        <li>
          <strong>Yves Sintomer</strong> (sciences politiques)
        </li>
        <li>
          <strong>Eyal Sivan</strong> (cinéaste)
        </li>
        <li>
          <strong>Arnaud Skornicki</strong> (politiste)
        </li>
        <li>
          <strong>Joy Sorman</strong> (écrivain)
        </li>
        <li>
          <strong>Catherine Stern</strong> (PCF - 28 Migrations)
        </li>
        <li>
          <strong>Federico Tarragoni</strong> (sociologue)
        </li>
        <li>
          <strong>Julien Théry</strong> (historien)
        </li>
        <li>
          <strong>Enzo Traverso</strong> (historien)
        </li>
        <li>
          <strong>Yves Vargas</strong> (professeur de philosophie)
        </li>
        <li>
          <strong>Eleni Varikas</strong> (professeur de théorie politique)
        </li>
        <li>
          <strong>Nicolas Vieillescazes</strong> (éditeur)
        </li>
        <li>
          <strong>Xavier Vigna</strong> (historien)
        </li>
        <li>
          <strong>Julien Vincent</strong> (historien)
        </li>
        <li>
          <strong>Fabrice Virgili</strong> (historien)
        </li>
        <li>
          <strong>Louis Weber</strong> (éditeur)
        </li>
        <li>
          <strong>Hela Yousfi</strong> (universitaire)
        </li>
        <li>
          <strong>Sophie Zafari</strong> (syndicaliste)
        </li>
        <li>
          <strong>Jean-Claude Zancarini</strong> (italianiste)
        </li>
        <li>
          <strong>Michelle Zancarini-Fournel</strong> (historienne)
        </li>
      </ul>
    </section>
  );
};

export default Content;
