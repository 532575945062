import React, { Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import Nav from "./Nav";
import Header from "./Header";
import Home from "./Home";

const App = () => {
  return (
    <Router>
      <Fragment>
        <Nav />
        <Header />
        <div className="App-main wrapper">
          <Route exact path="/" component={Home} />
        </div>
      </Fragment>
    </Router>
  );
};

export default App;
