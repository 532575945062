import React from "react";

import "./Share.css";
import { encode } from "punycode";

const Share = () => {
  const text = "Signez la pétition pour l'amnistie des Gilets Jaunes :";
  const url = document.URL;
  const hashtag = "AmnistiePourLesGJ ";

  return (
    <div className="Share">
      <h2>
        <strong>Partagez</strong> la pétition :
      </h2>
      <div className="Share-links">
        <a
          className="Share-facebook"
          target="_blank"
          rel="noopener noreferrer"
          href={encodeURI(`https://facebook.com/sharer/sharer.php?u=${url}`)}
        >
          <i className="fa-fw fab fa-facebook-f" />
        </a>

        <a
          className="Share-twitter"
          href={encodeURI(
            `https://twitter.com/intent/tweet/?text=${text}&url=${url}&hashtags=${hashtag}`
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-fw fab fa-twitter" />
        </a>

        <a
          className="Share-whatsapp"
          href={encodeURI(`whatsapp://send?text=${text} ${url}`)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-fw fab fa-whatsapp" />
        </a>

        <a
          className="Share-telegram"
          href={encodeURI(
            `https://telegram.me/share/url?text=${text}&url=${url}`
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-fw fab fa-telegram-plane" />
        </a>

        <a
          className="Share-linkedin"
          href={encodeURI(
            `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}&summary=${text}&source=${url}`
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-fw fab fa-linkedin-in" />
        </a>

        <a
          className="Share-reddit"
          href={encodeURI(`https://reddit.com/submit/?url=${url}`)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-fw fab fa-reddit-alien" />
        </a>

        <a
          className="Share-email"
          href={encodeURI(`mailto:?subject=${text}&body=${url}`)}
          target="_self"
          rel="noopener noreferrer"
        >
          <i className="fa-fw fas fa-envelope" />
        </a>
      </div>
    </div>
  );
};

export default Share;
