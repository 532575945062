import React, { Component } from "react";
import numeral from "numeral";
import firebase from "firebase/app";
import "firebase/firestore";

import "./Counter.css";

numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ","
  }
});
numeral.locale("fr");

firebase.initializeApp({
  apiKey: "AIzaSyCTxJc2sUaONNy7IUu-EgAVrccqpDmtWZY",
  authDomain: "le-media.firebaseapp.com",
  projectId: "le-media"
});

class Counter extends Component {
  state = { counter: 50 };

  componentDidMount() {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection("amnistie-giletsjaunes")
      .doc("parameters")
      .onSnapshot(doc => {
        this.setState(doc.data());
      });
  }

  render() {
    return (
      <div className="Counter">{numeral(this.state.counter).format("0,0")}</div>
    );
  }
}

export default Counter;
